import migration from "../assets/images/migration.jpg";
import kili from "../assets/images/kili.jpg";
import zanzibar from "../assets/images/zanzibar.jpg";
import manyara from "../assets/images/manyara.jpg";
import rhino from "../assets/images/rhino.jpg";
import elephant from "../assets/images/elephant.jpg";
import hyena from "../assets/images/hyena.jpg";
import dik from "../assets/images/dik-dik.jpg";
import kilimanjaro from "../assets/images/kilimanjaro.jpg";
export const nationalParksAndReserves = [
  {
    circuit: "Northern Circuit",
    description:
      "The Northern Circuit hosts Tanzania's most renowned game reserves and parks, attracting a large number of tourists. Iconic destinations like Serengeti, Ngorongoro Crater, Mount Kilimanjaro, Lake Manyara National Park, Tarangire National Park, and Arusha National Park are found here. Wildlife enthusiasts flock to witness the big five and experience the awe-inspiring Mt. Kilimanjaro climbs. The proximity of these parks allows for efficient exploration, making it ideal for those seeking diverse wildlife in a short timeframe. The Northern circuit is easily accessible, with travelers often flying into Arusha or Nairobi before embarking on their safari.",
    destinations: [
      {
        title: "Serengeti National Park",
        content:
          "Covering over 14,000 square kilometers, Serengeti is renowned for the annual wildebeest migration, offering a diverse ecosystem from savannah to dense forest.",
      },
      {
        title: "Ngorongoro Crater",
        content:
          "Considered a natural wonder, Ngorongoro boasts one of the world's largest concentrations of animals, including the big five, within a massive caldera.",
      },
      {
        title: "Mount Kilimanjaro",
        content:
          "Africa's highest mountain, standing at 19,340 feet, entices mountaineers and trekkers with its stunning views and three volcanic peaks.",
      },
      {
        title: "Lake Manyara National Park",
        content:
          "Nestled between the Rift Valley escarpment and Lake Manyara, this park is a paradise for bird watchers, with over 380 bird species.",
      },
      {
        title: "Tarangire National Park",
        content:
          "Known for its elephant population, Tarangire provides superb views during the dry season when animals migrate towards the Tarangire River.",
      },
      {
        title: "Arusha National Park",
        content:
          "Featured in John Wayne's movie 'Hatari', this park showcases the stunning Momela lakes, Mount Meru, and the Ngurdoto Crater, home to diverse wildlife.",
      },
    ],
  },
  {
    circuit: "Southern Circuit",
    description:
      "The Southern Circuit, located in Southern Tanzania, offers a more secluded and vast wilderness experience compared to the Northern Circuit. While attracting fewer tourists, it provides exceptional game viewing and a peaceful escape from the crowded North. Parks in this circuit include Selous Game Reserve, Ruaha National Park, Mikumi National Park, and Udzungwa Mountains National Park.",
    destinations: [
      {
        title: "Selous Game Reserve",
        content:
          "Africa's largest protected wildlife reserve, Selous spans over 20,000 square miles and features the great Rufiji River Delta, offering diverse wildlife and birdlife.",
      },
      {
        title: "Ruaha National Park",
        content:
          "With the Ruaha River as its centerpiece, this park supports large herds of elephants, buffalos, gazelles, crocodiles, and over 400 bird species.",
      },
      {
        title: "Mikumi National Park",
        content:
          "Positioned between the Uluguru Mountains and the Lumango Range, Mikumi is a popular spot for game viewing and picnics, known for its diverse wildlife and bird species.",
      },
      {
        title: "Udzungwa Mountains National Park",
        content:
          "Home to unique plant species, Udzungwa Mountains offer stunning scenery and a variety of primates, including the red colobus monkey.",
      },
    ],
  },
  {
    circuit: "Western Circuit",
    description:
      "The Western Circuit, situated around Lake Tanganyika, is renowned for its chimpanzee viewing opportunities. This circuit combines rugged mountains, equatorial forests, and the shores of Lake Tanganyika to create an exceptional African jungle safari experience. Parks in this circuit include Gombe Stream National Park, Mahale Mountains National Park, and Katavi National Park.",
    destinations: [
      {
        title: "Gombe Stream National Park",
        content:
          "Famous for Dr. Jane Goodall's research on chimpanzees, this park offers guided walks for visitors to observe chimpanzees and other primates.",
      },
      {
        title: "Mahale Mountains National Park",
        content:
          "Home to the largest population of wild chimpanzees globally, Mahale allows for close-up sightings in its lush tropical rainforest.",
      },
      {
        title: "Katavi National Park",
        content:
          "A remote wilderness destination, Katavi boasts abundant wildlife and spectacular scenery, providing a private safari experience.",
      },
    ],
  },
  {
    circuit: "Eastern Circuit",
    description:
      "Zanzibar, the gem of the Indian Ocean, is the focal point of the Eastern Circuit. This exotic spice island offers pristine beaches with clear turquoise waters, coral reefs for snorkeling and diving, and a rich history and culture. Zanzibar can be enjoyed as a standalone beach vacation or combined with a wildlife safari, providing a perfect blend of adventure and relaxation.",
    destinations: [
      {
        title: "Zanzibar",
        content:
          "With unspoiled beaches and historical sites like Stone Town, Zanzibar offers a tranquil escape. Visitors can explore the sultan's palaces, ancient Islamic ruins, and indulge in exotic fruits and spices.",
      },
    ],
  },
];

export const Itineraries = [
  {
    img: `${migration}`,
    title: "Grand Migration Camping Safari",
    description:
      "13 Days - (Manyara -> Tarangire -> Serengeti -> Ngorongoro -> Lake Eyasi -> Lake Natron)",
    days: [
      {
        Day: "Day 1",
        content:
          "Arrival at either Nairobi or Arusha. Overnight stay in Arusha",
      },
      {
        Day: "Day 2",
        content:
          "After breakfast, drive to Manyara National Park. Enjoy a lunch break at Adventure Migungani Camp Site. In the afternoon, indulge in a game drive at Lake Manyara National Park. Dinner and overnight stay at Adventure Migungani Lodge.",
      },
      {
        Day: "Day 3",
        content:
          "Explore Lake Manyara National Park with a morning game drive. Lunch at camp followed by a leisurely afternoon. Conclude the day with dinner and overnight at Adventure Migungani Lodge.",
      },
      {
        Day: "Day 4",
        content:
          "Depart after breakfast with a packed lunch for a day game drive at Tarangire National Park. Lunch break at the Tarangire picnic site. Return to Adventure Migungani Lodge for dinner and an overnight stay.",
      },
      {
        Day: "Day 5",
        content:
          "After breakfast, head to Serengeti National Park with a lunch break at Olduvai Gorge. Enjoy a short game drive on the way. Dinner and overnight at Seronera Camp Site.",
      },
      {
        Day: "Day 6",
        content:
          "Full day game drive of the mighty Serengeti, renowned for the largest migration of mammals on earth. Return for dinner & overnight at Seronera Camp Site.",
      },
      {
        Day: "Day 7",
        content:
          "Early morning game drive, followed by Tea / brunch. Proceed to Ngorongoro. Dinner and Overnight at Ngorongoro Simba Camp Site.",
      },
      {
        Day: "Day 8",
        content:
          " Descend into the Ngorongoro Crater for a full day tour after breakfast. Witness the 'big five', including the endangered black rhinos. Return to camp, pack up, and head to Lake Eyasi for dinner and overnight at the campsite.",
      },
      {
        Day: "Day 9",
        content:
          " Early morning breakfast and trek with the Hadzabe Bushmen. Lunch at camp and visit the Wakatoga Family in the afternoon. Dinner and Overnight at Camp.",
      },
      {
        Day: "Day 10",
        content:
          "Return to Adventure Camp. Enjoy a hot lunch at the Lodge. Dinner and Overnight at Adventure Lodge.",
      },
      {
        Day: "Day 11",
        content:
          "Drive to Lake Natron with packed lunch boxes. Afternoon flamingo viewing at Lake Natron. Dinner and overnight at Kamakia Camp Site.",
      },
      {
        Day: "Day 12",
        content:
          "Trek to the waterfalls after breakfast. Lunch break and return to Arusha for Dinner and Overnight stay.",
      },
      {
        Day: "Day 13",
        content: "Departure - End of Tour and departure.",
      },
    ],
  },
  {
    img: `${kili}`,
    title: "Wildlife Safari and Mount Kilimanjaro Climb",
    description:
      "15 Days – (Tarangire -> Manyara -> Serengeti -> Ngorongoro -> Mount Kilimanjaro)",
    days: [
      {
        Day: "Day 1",
        content: "Arrival, overnight at Impala Hotel.",
      },
      {
        Day: "Day 2",
        content:
          "After breakfast, head to Tarangire National Park for an exciting day of wildlife encounters, including elephants and tree-climbing lions. Lunch within the park and then drive to Mto wa Mbu. Dinner and Overnight at Migungani Lodge",
      },
      {
        Day: "Day 3",
        content:
          "Explore Lake Manyara National Park with packed lunch boxes, discovering diverse habitats and the famous 'Lion on the Trees' phenomenon. Dinner and Overnight at Migungani Lodge.",
      },
      {
        Day: "Day 4",
        content:
          "Journey to the vast Serengeti National Park, enjoying an afternoon of leisure and sundowner game viewing. Dinner and Overnight at Serengeti Camp Site.",
      },
      {
        Day: "Day 5",
        content:
          "Full day game viewing in the Serengeti along the Grumeti River, witnessing the great migration and various predators. Dinner and Overnight at Serengeti Camp Site.",
      },
      {
        Day: "Day 6",
        content:
          "Descend to Ngorongoro Crater via Olduvai Gorge. Dinner and Overnight at Ngorongoro Simba Camp Site.",
      },
      {
        Day: "Day 7",
        content:
          "Full day game viewing in the Ngorongoro Crater, experiencing diverse wildlife. Dinner and Overnight in Arusha.",
      },
      {
        Day: "Day 8",
        content:
          "Leisure in Arusha and briefing on Mount Kilimanjaro climb. Dinner and Overnight at Impala Hotel.",
      },
      {
        Day: "Day 9",
        content:
          "Drive to Marangu gate and commence the Kilimanjaro climb. The trail leads through the Rainy Forest to Mandara Hut at an altitude of 2700m, taking 3-4 hours.",
      },
      {
        Day: "Day 10",
        content:
          "Depart the forest, crossing open moorland to reach Horombo Hut at 3720m, covering 16 kilometers in 6-7 hours.",
      },
      {
        Day: "Day 11",
        content:
          "Depart the forest, crossing open moorland to reach Horombo Hut at 3720m, covering 16 kilometers in 6-7 hours.",
      },
      {
        Day: "Day 12",
        content:
          "Summit day! Start early for the challenging climb to Gilman's Point at 5681m, then push on to Uhuru Peak at 5895m, the highest point in Africa. Descend to Kibo Hut for a brief rest before continuing to Horombo Hut for the night.",
      },
      {
        Day: "Day 13",
        content:
          "Full day game viewing in the Ngorongoro Crater, experiencing diverse wildlife. Dinner and Overnight in Arusha.",
      },
      {
        Day: "Day 14",
        content:
          "Descend to Marangu and drive back to Arusha. Reflect on the incredible achievement and celebrate the successful Kilimanjaro climb. Dinner and Overnight at Impala Hotel.",
      },
      {
        Day: "Day 15",
        content: "Departure. End of safari",
      },
    ],
  },
  {
    img: `${zanzibar}`,
    title: "Zanzibar Explorer Safari",
    description:
      "13 Days – (Tarangire -> Manyara -> Serengeti -> Ngorongoro -> Zanzibar)",
    days: [
      {
        Day: "Day 1",
        content: "Arrival and overnight at Impala Hotel B/B.",
      },
      {
        Day: "Day 2",
        content:
          "Drive to Tarangire National Park, renowned for its scenic beauty and ancient baobab trees. Witness large migratory herds of elephants and the occasional sight of tree-climbing lions. Dinner and Overnight at Migungani Lodge.",
      },
      {
        Day: "Day 3",
        content:
          "Drive to Lake Manyara National Park, experiencing diverse habitats and the captivating 'Lion on the Trees' phenomenon. Dinner and Overnight at Migungani Lodge.",
      },
      {
        Day: "Day 4",
        content:
          " Depart for Serengeti National Park, marveling at the endless plains. Sundowner game viewing of Serengeti National Park. Dinner and Overnight Serengeti Camp Site.",
      },
      {
        Day: "Day 5",
        content:
          "Full day game viewing in the Mighty Serengeti, witnessing resident herds of Wildebeest, Zebras, and major predators. Dinner and Overnight Serengeti Camp Site.",
      },
      {
        Day: "Day 6",
        content:
          "Descend to the Ngorongoro Crater, stopping at Olduvai Gorge, a significant archaeological site. Dinner and Overnight at Ngorongoro Simba Campsite.",
      },
      {
        Day: "Day 7",
        content:
          "Descend into the Ngorongoro Crater for a full day of game viewing. Lunch break inside the crater and later game viewing en-route to Arusha. Dinner and Overnight in a hotel.",
      },
      {
        Day: "Day 8",
        content:
          "Leisure in Arusha, souvenir shopping, and catch a flight to Zanzibar. Transfer to Tembo House Hotel. Dinner and Overnight at Tembo House Hotel.",
      },
      {
        Day: "Day 9",
        content:
          "City tour, spice tour, and visit to Mangapwani for the slave cave. Dinner at African House Restaurant. Overnight at Tembo House Hotel.",
      },
      {
        Day: "Day 10",
        content:
          " Boat trip to Prison Island to see giant tortoises, swim, and snorkel. Dinner at Blue Bay Beach Resort. Overnight at Tembo House Hotel.",
      },
      {
        Day: "Day 11",
        content:
          "Dolphin excursion in Kizimkazi Village, swimming with dolphins. Dinner at Zanzibar Beach Resort. Overnight at Tembo House Hotel.",
      },
      {
        Day: "Day 12",
        content:
          "Souvenir shopping, board Hydra Foil to Dar es Salaam. Dinner and Overnight at New Africa Hotel.",
      },
      {
        Day: "Day 13",
        content: " Dar es Salaam City Tour and Departure.",
      },
    ],
  },
  {
    img: `${manyara}`,
    title: "Serene Safari Odyssey",
    description:
      "7 Days – (Arusha Nation Park -> Lake Manyara ->  Ngorongoro -> Serengeti -> Tarangire)",
    days: [
      {
        Day: "Day 1",
        content:
          "Depart for a captivating Arusha National Park tour made famous by John Wayne's movie 'Hatari', renowned for its black and white Colobus monkeys and the scenic Mount Meru. Enjoy the evening at Momella Lodge.",
      },
      {
        Day: "Day 2",
        content:
          "After breakfast, embark on a thrilling game drive at Lake Manyara National Park, known for its diverse birdlife, hippos, buffalos, and famous tree-climbing lions. Relax and unwind at Lake Manyara Hotel, Serena Lodge, Gibbs Farm, or Kirurumo Tented Lodge.",
      },
      {
        Day: "Day 3",
        content:
          "Depart for the Serengeti with a picnic lunch via Olduvai Gorge. Explore the museum and enjoy a game drive in the Serengeti, renowned for the annual migration of herbivores and exciting predator-prey interactions. Overnight at Ndutu, Seronera Wildlife, Serengeti Sopa, or Serena Lodge.",
      },
      {
        Day: "Day 4",
        content:
          "After breakfast, delve into another thrilling game drive in the Serengeti. Lunch or have a picnic en route to the Ngorongoro Crater. Enjoy the evening at Ngorongoro Crater Lodge, Wildlife Lodge, Sopa Lodge, or Serena Lodge.",
      },
      {
        Day: "Day 5",
        content:
          "Embark on a full-day crater tour at Ngorongoro, a natural wonder hosting the 'big five,' including endangered black rhinos. Relish the stunning beauty of the crater. Dinner and overnight at Ngorongoro Crater Lodge, Wildlife Lodge, Sopa Lodge, Serena Lodge, or Gibbs Farm.",
      },
      {
        Day: "Day 6",
        content:
          "After breakfast, depart for Tarangire with an afternoon game drive. Experience the park's renowned elephants and enjoy the picturesque baobab trees. Dinner and overnight at Tarangire Safari Lodge or Sopa Lodge.",
      },
      {
        Day: "Day 7",
        content:
          "Embark on a morning game drive in Tarangire Park, then depart for Arusha. Enjoy lunch in Arusha, marking the end of the safari. Depart for the airport, cherishing the memories of an unforgettable Tanzanian wildlife adventure.",
      },
    ],
  },

  {
    img: `${elephant}`,
    title: "Tanzanian Splendor",
    description:
      "5 Days – (Lake Manyara -> Ngorongoro -> Serengeti -> Tarangire)",
    days: [
      {
        Day: "Day 1",
        content:
          "Commence the journey at 08:30 for Lake Manyara Park. Enjoy a game drive with a picnic lunch and stay overnight at Lake Manyara Hotel, Serena Lodge, Gibbs Farm, or Kirurumo Tented Lodge.",
      },
      {
        Day: "Day 2",
        content:
          "After breakfast, head to Serengeti via Olduvai Gorge. Visit the museum and proceed to Seronera Wildlife Lodge, Sopa Lodge, or Serena Lodge for dinner and overnight.",
      },
      {
        Day: "Day 3",
        content:
          "Embark on a morning Serengeti game drive with a picnic lunch. Later, journey to Ngorongoro for an overnight stay at Ngorongoro Crater, Wildlife Lodge, Sopa Lodge, or Serena Lodge.",
      },
      {
        Day: "Day 4",
        content:
          "Enjoy a half-day crater tour with a picnic lunch, followed by an afternoon drive to Tarangire Safari Lodge or Sopa Lodge for dinner and overnight.",
      },
      {
        Day: "Day 5",
        content:
          "Conclude the adventure with a morning game drive in Tarangire, lunch at the lodge, and an afternoon drive back to Arusha, with an optional stopover at the Snake Park en route.",
      },
    ],
  },
  {
    img: `${kilimanjaro}`,
    title: "Summit Symphony",
    description: `7 Days – Marangu route \n`,
    days: [
      {
        Day: "Day 1",
        content:
          "Arrive and spend the night in a hotel. Receive a detailed briefing on the thrilling Mt. Kilimanjaro climb.",
      },
      {
        Day: "Day 2",
        content:
          "Drive to Marangu gate and embark on the climb through enchanting rainforests. Reach Mandara Hut at an altitude of 9000 ft (2725m) after a 3-4 hour climb.",
      },
      {
        Day: "Day 3",
        content:
          "Ascend through alpine meadows, relishing the changing flora and views of Mawenzi and Kibo peaks. Arrive at Horombo Hut, covering 12 km from Mandara, with an altitude of 12335 ft (3780m).",
      },
      {
        Day: "Day 4",
        content:
          "Take a day to acclimatize at Horombo Hut, adjusting to the changing altitude.",
      },
      {
        Day: "Day 5",
        content:
          "Resume the climb through a rugged and rocky landscape, encountering groundsels and lobelias. Reach Kibo Hut at an altitude of 15520 ft (4730 m) after a 5 to 6-hour climb.",
      },
      {
        Day: "Day 6",
        content:
          "Begin the final ascent in the early morning, navigating the frozen scree. Conquer the challenging Giliman Point, reaching an altitude of 18635 ft (5680 m). Within an hour, attain Uhuru Peak along the Crater's rim at an altitude of 19340 ft (5895 m). Descend to Horombo Hut for an overnight stay.",
      },
      {
        Day: "Day 7",
        content:
          "Return to Marangu Gate, rest, enjoy a light lunch, and transfer to Arusha. The climb concludes with memories of an incredible journey.",
      },
    ],
  },
  {
    img: `${hyena}`,
    title: "Tanzanian Tapestry",
    description: "5 Days – (Lake Manyara -> Ngorongoro -> Tarangire)",
    days: [
      {
        Day: "Day 1",
        content:
          "Embark on your journey at 14:00, setting the stage for an evening in Karatu. Enjoy dinner and an overnight stay at Gibbs Farm or Karatu.",
      },
      {
        Day: "Day 2",
        content:
          "After breakfast, immerse yourself in a crater tour with a picnic lunch, concluding the day with dinner and an overnight stay at Ngorongoro Crater, Wildlife Lodge, Sopa Lodge, or Serena Lodges.",
      },
      {
        Day: "Day 3",
        content:
          "Discover the wonders of Manyara with a morning game drive, returning to the lodge for lunch. Experience another captivating afternoon game drive. Dinner and overnight at Lake Manyara Hotel.",
      },
      {
        Day: "Day 4",
        content:
          "Embark on a journey to Tarangire after breakfast, indulging in a game drive within the park. Retire for the night at Tarangire Safari Lodge or Sopa Lodge.",
      },
      {
        Day: "Day 5",
        content:
          "Commence the day with a final game drive in Tarangire, followed by breakfast. Bid farewell as you return to Arusha with lunch in Arusha town.",
      },
    ],
  },
  {
    img: `${dik}`,
    title: "Serenade of the Wild",
    description: "5 Days – (Lake Manyara -> Ngorongoro -> Serengeti)",
    days: [
      {
        Day: "Day 1",
        content:
          "Embark on your safari at 14:00, heading to Karatu. Enjoy dinner and an overnight stay at Gibbs Farm or Kifaru Lodge.",
      },
      {
        Day: "Day 2",
        content:
          "Post-breakfast, venture into the Serengeti with packed lunch boxes. Experience a game drive en route, settling in for the night at Seronera Wildlife Lodge, Serengeti Sopa Lodge, or Serena Lodge.",
      },
      {
        Day: "Day 3",
        content:
          "After breakfast, embark on a thrilling Serengeti game drive with a picnic lunch. Visit the Olduvai Gorge Museum before heading to Ngorongoro for an overnight stay at Ngorongoro Crater, Wildlife Lodge, Sopa Lodge, or Serena Lodge.",
      },
      {
        Day: "Day 4",
        content:
          "Start your day with a crater tour and lunch, then drive to Lake Manyara for dinner and an overnight stay at Lake Manyara Hotel or Kirurumo Tented Lodge.",
      },
      {
        Day: "Day 5",
        content:
          "Explore Lake Manyara Park after breakfast, followed by lunch at Manyara Hotel. Conclude your safari with a drive to Arusha, with an optional stopover at Snake Park en route.",
      },
    ],
  },
  {
    img: `${rhino}`,
    title: "Essence of Tanzania",
    description: "6 Days – (Lake Manyara -> Serengeti -> Ngorongoro)",
    days: [
      {
        Day: "Day 1",
        content:
          "Depart at 14:00 for Karatu. Enjoy dinner and overnight at Gibbs Farm or Kifaru Lodge.",
      },
      {
        Day: "Day 2",
        content:
          "After breakfast, journey to Serengeti with a game drive en route and a picnic lunch. Dinner and overnight at Seronera Wildlife, Lobo Wildlife, or Sopa Lodge/Serena Lodge.",
      },
      {
        Day: "Day 3",
        content:
          "Embark on a morning game drive in Serengeti, with an afternoon break for lunch. Enjoy an evening game drive, followed by dinner and overnight at Seronera Wildlife, Serengeti Sopa, or Serena Lodge.",
      },
      {
        Day: "Day 4",
        content:
          "After breakfast, head to Ngorongoro via Olduvai Gorge. Dinner and overnight at Ngorongoro Wildlife, Crater, Sopa, or Serena Lodge.",
      },
      {
        Day: "Day 5",
        content:
          "After breakfast, experience a full-day crater tour with a picnic lunch. Dinner and overnight at Lake Manyara Hotel, Gibbs Farm, or Kirurumo Tented Camp.",
      },
      {
        Day: "Day 6",
        content:
          "Begin the day with a Lake Manyara game drive, followed by lunch. Depart for Arusha with an optional stopover at the snake park en route, concluding this memorable Tanzanian adventure.",
      },
    ],
  },
];
