import React, { useEffect } from "react";
import styled from "styled-components";
import img0 from "../assets/images/0.jpg";
import img1 from "../assets/images/1.jpg";
import img2 from "../assets/images/2.jpg";
import img3 from "../assets/images/3.jpg";
import img4 from "../assets/images/4.jpg";
import img5 from "../assets/images/5.jpg";
import img6 from "../assets/images/6.jpg";
import img7 from "../assets/images/7.jpg";
import img8 from "../assets/images/8.jpg";
import img9 from "../assets/images/9.jpg";
import img10 from "../assets/images/harshil-gudka--2qN2QQwT8s-unsplash.jpg";

import {
  MainContainer,
  Text,
} from "../components/styledComponents/commonComponents";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <MainContainer>
      <Header>
        <Text.Title>Explore Tanzania's Untamed Beauty</Text.Title>
      </Header>
      <Section>
        <Text.Title fontSize="2.5rem">Why Tanzania?</Text.Title>
        <Text>
          Tanzania stands as a crown jewel among African safari destinations, a
          wildlife paradise where pristine landscapes and breathtaking
          encounters with nature await. Covering an impressive 25% of its land
          as national parks and wildlife reserves, Tanzania boasts the world's
          largest concentration of animals, offering unparalleled beauty and
          attractions found nowhere else in Africa. Among its gems are the
          majestic Mount Kilimanjaro, Africa's highest peak at 19,340 feet, the
          iconic Serengeti National Park, and the awe-inspiring Ngorongoro
          Crater – hailed as a natural wonder of the world.
        </Text>
        <br />
        <ImageGrid>
          <Column>
            <Image src={img9} alt="Image 2" />
          </Column>
          <Column>
            <Text>
              Diversity thrives in Tanzania's ecosystem, showcasing an
              incredible variety of flora and fauna across its temperate climate
              that persists throughout the year. From coastal marshes to savanna
              plains, and dense tropical forests, Tanzania invites exploration
              into a tapestry of landscapes that defy expectations.
            </Text>
            <br />
          </Column>
          <Column>
            <Image src={img8} alt="Image 1" />
          </Column>
        </ImageGrid>
        <br />
        <Text>
          {" "}
          Beyond its natural wonders, Tanzania is a testament to unity and
          peace. Over 150 different tribes coexist harmoniously in this
          democratic state, earning it the unofficial title of the "Geneva of
          Africa." With a commitment to human rights and a history of mediating
          conflicts across the continent, Tanzania exudes a spirit of
          tranquility that enhances the safari experience.
        </Text>{" "}
        <Text>
          {" "}
          The tourist attractions in Tanzania remain untouched by mass tourism,
          proudly bearing signs proclaiming, "Here the world is still young and
          fragile, held in trust for your sons and ours." This underscores
          Tanzanians' dedication to safeguarding their heritage for the benefit
          of future generations.
        </Text>
      </Section>
      <hr />
      <GridContainer>
        <Column>
          <Image src={img0} alt="Image 1" />
          <Image src={img1} alt="Image 2" />
          <Image src={img2} alt="Image 3" />
        </Column>
        <Column>
          <Image src={img3} alt="Image 8" />
          <Image src={img5} alt="Image 10" />
        </Column>
        <Column>
          <Image src={img6} alt="Image 14" />
          <Image src={img7} alt="Image 15" />
          <Image src={img4} alt="Image 16" />
        </Column>
      </GridContainer>
      <hr />
      <Section>
        <Text.Title fontSize="2.5rem">
          Who are Adventure Tours & Safaris?
        </Text.Title>
        <Text>
          {" "}
          Adventure Tours and Safaris Ltd., born and bred in Tanzania, embodies
          the spirit of personalized safari experiences. Founded in 1984 by two
          brothers in Arusha, the gateway to Tanzania's renowned tourist
          attractions, the company remains committed to offering bespoke safaris
          that go beyond the typical tourist circuit. Our commitment to
          personalized experiences extends to group, individual, and honeymoon
          safaris. We collaborate with universities, schools, and corporations,
          tailoring safaris to accommodate special needs such as academic
          research and business meetings. Additionally, we cater to special
          interest groups like bird watchers, mountaineering clubs, and
          photography enthusiasts. With over 15 years of organizing safaris into
          Tanzania's wild, Adventure Tours and Safaris ensures that every trip
          is far from routine. Exceptional customer service has been our top
          priority from day one, and our passion for helping customers achieve
          their dream of a spectacular African safari remains unwavering.
        </Text>
      </Section>
      <Section>
        <Text.Title fontSize="2.5rem">
          Why Choose Adventure Tours and Safaris?
        </Text.Title>{" "}
        <Text>
          At Adventure Tours and Safaris, every day is an encounter with the
          wild, a rendezvous with Tanzania's wildlife that has been meticulously
          conserved for mankind. What sets us apart is our commitment to
          crafting professionally designed, unique, and personalized safaris,
          ensuring you're not just a number in the crowd.
        </Text>
        <ResponsiveGrid>
          <Card>
            <Text.SemiBold>No Middleman</Text.SemiBold>
            <hr />
            <Text color="#000000">
              We own the safari vehicles and personally negotiate with the best
              safari lodges and campsites. This direct approach ensures value
              for a fair price, with no intermediary between you and the
              adventure of a lifetime.
            </Text>
          </Card>
          <Card>
            <Text.SemiBold>Our Guides</Text.SemiBold>
            <hr />
            <Text color="#000000">
              Born and raised in Tanzania, our guides are not just experts in
              wildlife but also intimately acquainted with local tribes. Their
              extensive experience and knowledge enhance your safari, providing
              insights into both the animals and the communities around the
              parks.
            </Text>
          </Card>
          <Card>
            <Text.SemiBold>Our Team</Text.SemiBold>
            <hr />
            <Text color="#000000">
              Led by Karim, fondly known as the "bushmaster," our team of safari
              planners collaborates closely with guides to identify optimal
              wildlife viewing spots. They constantly innovate, creating new
              itineraries with added features and ensuring guides are
              well-trained in delivering top-notch customer service.
            </Text>
          </Card>
          <Card>
            <Text.SemiBold>Our Vehicles</Text.SemiBold>
            <hr />
            <Text color="#000000">
              Customized 4X4 Land Rovers offer comfort and excellent wildlife
              viewing. With every client guaranteed a window seat for an
              unobstructed view, our vehicles, equipped with a retractable roof,
              handle the rugged terrain of game parks with a stellar safety
              record.
            </Text>
          </Card>
          <Card>
            <Text.SemiBold>Competitive Prices</Text.SemiBold>
            <hr />
            <Text color="#000000">
              Since we run our own safaris, we provide the best deals directly
              to our valued customers. Our aim is to make adventure accessible
              in the true African spirit at a reasonable cost. We offer
              transparent pricing, always mindful of your budget. Your
              contentment is our constant pursuit on every safari.
            </Text>
          </Card>
          <Card>
            <Text.SemiBold>Our Promise</Text.SemiBold>
            <hr />
            <Text color="#000000">
              Treat you as Friends. We welcome you into our world with warmth
              and camaraderie. Your comfort and satisfaction are our top
              priorities.
            </Text>
          </Card>
        </ResponsiveGrid>
      </Section>
    </MainContainer>
  );
};

export default About;

const GridContainer = styled.div`
  display: flex;
  background-color: white;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex: 1;
  padding: 0.4286rem 0.2143rem;
  display: flex;
  flex-direction: column;
  gap: 0.3571rem;
  overflow: hidden; /* Hide overflow content */
`;

const Image = styled.img`
  width: 100%;
  flex-grow: 1;
  object-fit: cover;
  &:hover,
  &:active {
    transform: scale(1.009);
    transition: transform 0.3s ease-in-out;
  }
`;

const Header = styled.header`
  background-image: url(${img10});
  background-size: cover;
  background-position: center;
  color: #e4d8c8;
  text-align: center;
  padding: 8.7143rem;
  @media (max-width: 54.8571rem) {
    text-align: center;
    padding: 4.5rem;
  }
`;

const Section = styled.section`
  padding: 1rem 2.5714rem;
  text-align: justify;
  display: flex;
  flex-direction: column;
  @media (max-width: 54.8571rem) {
    text-align: center;
    padding: 1rem;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.4286rem;
  align-items: flex-start;
  @media (max-width: 54.8571rem) {
    grid-template-columns: 1fr;
  }
`;

const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.4286rem;
  padding: 1.4286rem;

  @media (max-width: 54.8571rem) {
    grid-template-columns: repeat(auto-fill, minmax(17.8571rem, 1fr));
  }
`;

const Card = styled.div`
  background-color: #c4c0b4;
  border-radius: 0.5714rem;
  padding: 1.4286rem;
  box-shadow: 0 0.2857rem 0.5714rem rgba(0, 0, 0, 0.1);
  color: black;
  text-align: left;
  @media (max-width: 54.8571rem) {
    text-align: center;
  }
`;
