import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainContainer, Text } from "./styledComponents/commonComponents";
import { nationalParksAndReserves, Itineraries } from "../assets/content";
import ModalLayout from "./modal";

const Safaris = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [openIndex, setOpenIndex] = useState(-1);
  const [openSubIndex, setOpenSubIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [content, setcontent] = useState();
  const handleOpen = (content) => {
    setcontent(content);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const handleSubToggle = (index) => {
    setOpenSubIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <>
      <MainContainer>
        <Section>
          <Text.Title>National Parks and Reserves</Text.Title>
          <Text>
            Tanzania boasts an extensive array of game reserves and national
            parks, providing a diverse range of wildlife encounters and
            breathtaking landscapes. These reserves and parks are categorized
            into four circuits based on geographical proximity and topographical
            variations. Each circuit offers unique attractions and specialties,
            creating distinct safari experiences. The four circuits are:
          </Text>
        </Section>
        <Section>
          <ListContainer>
            {nationalParksAndReserves.map((item, index) => (
              <ListItem key={index}>
                <ItemHeader
                  alignment="center"
                  onClick={() => handleToggle(index)}
                >
                  <ArrowIcon isOpen={openIndex === index}>&#9660;</ArrowIcon>
                  <Text.SemiTitle> {item.circuit}</Text.SemiTitle>
                </ItemHeader>
                {openIndex === index && (
                  <ItemContent>
                    <hr />
                    <Text color="000000"> {item.description}</Text>
                    {item.destinations.map((destination, index) => (
                      <>
                        <ListItem key={index}>
                          <ItemHeader onClick={() => handleSubToggle(index)}>
                            <ArrowIcon isOpen={openSubIndex === index}>
                              &#9660;
                            </ArrowIcon>
                            <Text.SemiTitle>
                              {" "}
                              {destination.title}
                            </Text.SemiTitle>
                          </ItemHeader>
                          {openSubIndex === index && (
                            <ItemContent>
                              <Text color="000000">{destination.content}</Text>
                            </ItemContent>
                          )}
                        </ListItem>
                      </>
                    ))}
                  </ItemContent>
                )}
              </ListItem>
            ))}
          </ListContainer>
        </Section>
        <Section>
          <ModalLayout
            // key={index}
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            content={content}
          />
          <Text.Title>Itineraries</Text.Title>
          <ResponsiveGrid>
            {Itineraries.map((itinery, index) => (
              <>
                <Column>
                  <Card>
                    {/* <ImageContainer> */}
                    <Image Noanimation="true" src={itinery.img} />
                    {/* </ImageContainer> */}
                    <div className="p-3">
                      <Text.SemiBold>{itinery.title}</Text.SemiBold>
                      <br />
                      <Text.SemiTitle color="#000000">
                        {itinery.description}
                      </Text.SemiTitle>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <ReadButton onClick={() => handleOpen(itinery.days)}>
                          Read More
                        </ReadButton>
                      </div>
                    </div>
                  </Card>
                </Column>
              </>
            ))}
          </ResponsiveGrid>
        </Section>
      </MainContainer>
    </>
  );
};

export default Safaris;

const ListContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`;

const ListItem = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 8px;
  overflow: hidden;
`;

const ItemHeader = styled.div`
  background-color: #c4c0b4;
  padding: 10px;
  cursor: pointer;
  display: flex;
  gap: 14px;
  justify-content: ${(props) => (props.alignment ? props.alignment : "")};
  align-items: center;
  color: #000000;
`;

const ArrowIcon = styled.div`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
`;

const ItemContent = styled.div`
  padding: 10px;
  background-color: #c4c0b4;
  color: black;
`;

const Section = styled.section`
  padding: 1rem 2.5714rem;
  text-align: justify;
  display: flex;
  flex-direction: column;
  @media (max-width: 54.8571rem) {
    text-align: center;
    padding: 1rem;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 0.4286rem 0.2143rem;
  display: flex;
  flex-direction: column;
  gap: 0.3571rem;
  overflow: hidden; /* Hide overflow content */
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  flex-grow: 1;
  object-fit: cover;
  &:hover,
  &:active {
    transform: ${(props) => (props.Noanimation ? "none" : "scale(1.009)")};
    transition: transform 0.3s ease-in-out;
  }
`;

const Card = styled.div`
  background-color: #c4c0b4;
  border-radius: 0.5714rem;
  /* padding: 1.4286rem 0; */
  box-shadow: 0 0.2857rem 0.5714rem rgba(0, 0, 0, 0.1);
  color: black;
  text-align: left;
  @media (max-width: 54.8571rem) {
    text-align: center;
  }
`;

const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.4286rem;
  padding: 1.4286rem;

  @media (max-width: 54.8571rem) {
    grid-template-columns: repeat(auto-fill, minmax(17.8571rem, 1fr));
  }
`;

const ReadButton = styled.button`
  padding: 0.5714rem 1rem 0.5714rem 1rem;
  border-radius: 1.7143rem;
  border: none;
  font-family: "Baloo Bhai 2";
  font-weight: 600;
  font-size: 1rem;
  background-color: #e4d8c8;
  width: 30%;
  box-shadow: 0 0.5em 0.5em -0.4em #3f3c32;
  cursor: pointer;
  &:hover,
  :focus {
    transform: scale(1.01);
  }
  @media (max-width: 54.8571rem) {
    width: auto;
  }
`;
