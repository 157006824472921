import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logos from "../assets/images/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { motion } from "framer-motion";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [y, setY] = useState(0);
  const variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 500, damping: 50 },
    },

    closed: { opacity: 1, y: y, transition: { duration: 0.5 } },
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isMobile = window.matchMedia("(max-width: 54.8571rem)");

  isMobile.onchange = (e) => {
    if (e.currentTarget.matches) {
      setY(50);
    } else {
      setY(0);
    }
  };

  return (
    <StickyNavbar>
      <NavbarContainer>
        <LogoAndMenuContainer>
          <LogoContainer>
            <Logo src={logos} alt="logo" onClick={scrollToTop} />
          </LogoContainer>
          <HamburgerMenu fontSize="large">
            <MenuIcon
              onClick={() => {
                setMenuOpen((menuOpen) => !menuOpen);
              }}
            />
          </HamburgerMenu>
        </LogoAndMenuContainer>

        <NavbarItems
          open={menuOpen}
          animate={menuOpen ? "open" : "closed"}
          variants={variants}
        >
          <NavbarListItem>
            <NavbarLink exact to="/" activeClassName="active">
              Home
            </NavbarLink>
          </NavbarListItem>
          <NavbarListItem>
            <NavbarLink to="/about" activeClassName="active">
              About Us
            </NavbarLink>
          </NavbarListItem>
          <NavbarListItem>
            <NavbarLink to="/safaris" activeClassName="active">
              Safaris
            </NavbarLink>
          </NavbarListItem>
        </NavbarItems>
      </NavbarContainer>
    </StickyNavbar>
  );
};

export default Navbar;

const StickyNavbar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const NavbarContainer = styled.div`
  background: #96462f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  @media screen and (max-width: 54.8571rem) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const HamburgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-right: 1rem;

  @media screen and (min-width: 54.8571rem) {
    display: none;
  }
`;
const LogoAndMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 54.8571rem) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NavbarItems = styled(motion.ul)`
  display: flex;
  list-style: none;
  gap: 1rem;
  margin: 0;
  padding: 0;
  align-items: center;

  @media screen and (max-width: 54.8571rem) {
    flex-direction: column;
    display: ${(props) => (props.open ? "flex" : "none")};
    background: #96462f;
    padding: 1rem 0;
    align-items: center;
    width: 100%;
    background: #ab4f36;
    gap: 1.5rem;
  }
`;

const NavbarListItem = styled.li`
  &:hover {
    background-color: #646e56;
    border-radius: 1.4286rem;
  }
`;

const NavbarLink = styled(NavLink)`
  color: #e4d8c8;
  font-size: 1.1429rem;
  text-decoration: none;
  font-weight: 400;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
  border-radius: 1.4286rem;
  white-space: nowrap;

  &.active {
    background-color: #646e56;
    font-weight: 700;
    padding: 0.5rem 1rem;
  }

  &:hover {
    background-color: #646e56;
    padding: 0.5rem 1rem;
  }

  @media screen and (max-width: 54.8571rem) {
    font-size: 1.1rem;
    padding: 0.25rem;
  }
`;

const LogoContainer = styled.div`
  min-width: 4.2857rem;
  min-height: 3.5714rem;
  max-width: 5.2143rem;
`;

const Logo = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;
