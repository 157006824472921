import React, { useEffect } from "react";
import hero from "../assets/images/Hero.jpg";
import Giraffe from "../assets/images/giraffe.jpg";
import Hadzabe from "../assets/images/hadzabe.jpg";
import { motion, useTransform, useScroll } from "framer-motion";
import Maasai from "../assets/images/Maasai.jpg";
import Kilimanjaro from "../assets/images/Mt.kilimanjaro.jpg";
import { Text, MainContainer } from "./styledComponents/commonComponents.js";
import {
  HeroWrapper,
  TextContent,
  ContactButton,
  ImageWrapper,
  ScreenTwo,
  ScreenThree,
  ScreenFour,
  TextSection,
  ScreenFive,
} from "./styledComponents/home.js";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const scrollToBottom = () => {
    window.scroll({
      top: document.body.scrollHeight, // or document.scrollingElement || document.body
      left: 0,
      behavior: "smooth",
    });
  };
  const { scrollY } = useScroll();
  const screenOne = useTransform(scrollY, [0, 800], [1, 0.8]);
  const screenTwo = useTransform(scrollY, [0, 600], [0.8, 1]);
  return (
    <MainContainer>
      <motion.div
        style={{
          width: "100%",
          position: "relative",
          scale: screenOne,
        }}
      >
        <HeroWrapper>
          <TextContent>
            <Text.Title>
              Discover Tanzania <br /> with Adventure Tours <br />& Safaris
            </Text.Title>
            <Text>
              Embark on an Extraordinary Journey Through Tanzania's Untamed
              Wilderness.
            </Text>
            <ContactButton type="button" onClick={scrollToBottom}>
              CONTACT US
            </ContactButton>
          </TextContent>
          <ImageWrapper>
            <ImageWrapper.Image alt="Logo" src={hero} />
          </ImageWrapper>
        </HeroWrapper>
      </motion.div>

      <motion.div
        style={{
          width: "100%",
          position: "relative",
          scale: screenTwo,
        }}
      >
        <ScreenTwo>
          <TextContent>
            <Text.SemiTitle>
              Experience the Wild, Embrace the Adventure.
            </Text.SemiTitle>
            <Text.Title>
              Unleash Your Senses in the Breathtaking Symphony of Nature
            </Text.Title>
          </TextContent>
          <TextSection>
            <Text>
              Come and immerse yourself in the captivating symphony of nature!
              Listen to the enchanting melodies of the bush, feel the thunderous
              rumble of running herds, and witness the mighty roar of the
              beasts. Prepare to be swept away by the unparalleled beauty of the
              Serengeti and the awe-inspiring splendor of the Ngorongoro.
            </Text>
            <ScreenTwo.ParkWrapper>
              <ScreenTwo.ParkContainer>
                <ScreenTwo.ParkContentWrapper>
                  <Text.SemiBold>Ngorongoro National Park</Text.SemiBold>
                  <Text>
                    Explore the Ngorongoro Crater, the world's largest intact
                    volcanic caldera. Discover a diverse ecosystem teeming with
                    wildlife, including Africa's densest population of lions.
                    Witness nature's harmonious balance in this extraordinary
                    setting.
                  </Text>
                </ScreenTwo.ParkContentWrapper>
              </ScreenTwo.ParkContainer>
              <ScreenTwo.ParkContainer>
                <ScreenTwo.ParkContentWrapper>
                  <Text.SemiBold>Serengeti National Park</Text.SemiBold>
                  <Text>
                    Witness the world's largest mammal migration in the
                    Serengeti. Over two million animals embark on an epic
                    journey across the plains, creating an awe-inspiring
                    spectacle known as the Great Migration.
                  </Text>
                </ScreenTwo.ParkContentWrapper>
              </ScreenTwo.ParkContainer>
            </ScreenTwo.ParkWrapper>
          </TextSection>
        </ScreenTwo>
        <ScreenTwo.ImageContainer>
          <ImageWrapper.Image alt="giraffe" src={Giraffe} />
        </ScreenTwo.ImageContainer>
      </motion.div>

      <ScreenThree>
        <TextContent centered>
          <Text.SemiTitle>
            Unveiling Ancient Traditions and Shared Heritage
          </Text.SemiTitle>
          <Text.Title>Uncover Tanzania's Cultural Treasures</Text.Title>
          <Text>
            At Adventure Tours and Safaris, we invite you to embark on a
            once-in-a-lifetime journey. Delve into the heart of Tanzania and
            discover the Hadzabe tribe as they pursue their ancestral hunt,
            unlocking the secrets of their ancient traditions. Immerse yourself
            in the vibrant culture of the Maasai people, guardians of their
            ancestral lands, and gain a profound understanding of our shared
            heritage at the legendary Olduvai Gorge.
          </Text>
        </TextContent>
        <ScreenThree.TribeContainer>
          <ScreenThree.TribeWrapper>
            <ScreenThree.TribeTextWrapper>
              <Text.SemiBold>The Hadzabe Tribe</Text.SemiBold>
              <Text color="#000000">
                The Hadzabe tribe, one of the last hunter-gather communities in
                Africa, offers a captivating glimpse into ancient ways of life.
                Join them on a unique adventure, unlocking the secrets of their
                ancestral hunt and experiencing the essence of their timeless
                traditions.
              </Text>
            </ScreenThree.TribeTextWrapper>
            <ImageWrapper>
              <ImageWrapper.Image src={Hadzabe} />
            </ImageWrapper>
          </ScreenThree.TribeWrapper>
          <ScreenThree.TribeWrapper>
            <ScreenThree.TribeTextWrapper>
              <Text.SemiBold>The Maasai Tribe</Text.SemiBold>
              <Text color="#000000">
                Maasai Marvel: The Maasai people, renowned for their striking
                red attire and captivating cultural rituals, are known as fierce
                warriors and proud guardians of their ancestral lands. Immerse
                yourself in their rich traditions and witness their harmonious
                coexistence with wildlife in the pristine landscapes of
                Tanzania.
              </Text>
            </ScreenThree.TribeTextWrapper>
            <ImageWrapper>
              <ImageWrapper.Image src={Maasai} />
            </ImageWrapper>
          </ScreenThree.TribeWrapper>
        </ScreenThree.TribeContainer>
      </ScreenThree>

      <ScreenFour>
        <TextSection>
          <TextContent>
            <Text.Title>
              Conquer Mount Kilimanjaro: Scale the Majestic Crown of Africa
            </Text.Title>
            <Text>
              For the adventurous souls seeking to conquer new heights, prepare
              to ascend Mount Kilimanjaro, Africa's majestic crown. Experience
              the exhilaration of standing atop the highest peak in Africa,
              surrounded by breathtaking vistas that stretch as far as the eye
              can see.
            </Text>
          </TextContent>
        </TextSection>
        <ImageWrapper height>
          <ImageWrapper.Image src={Kilimanjaro} alt="Mount Kilimanjaro" />
        </ImageWrapper>
      </ScreenFour>

      <ScreenFive>
        <TextSection centered>
          <Text.Title>
            Unleash Tanzania's Treasures: Adventure Tours & Safaris
          </Text.Title>
          <Text>
            Adventure Tours and Safaris is your gateway to unlocking the
            treasures of Tanzania. We are a team of passionate explorers,
            intimately familiar with every corner of this extraordinary country.
            With our extensive knowledge and expertise, we will guide you on a
            captivating journey, revealing Tanzania's hidden gems and showcasing
            its natural wonders in all their glory.
          </Text>
          <Text>
            Join us and discover the essence of Tanzania, a land brimming with
            wonders, where every step brings you closer to the untamed beauty of
            nature. Let Adventure Tours and Safaris be your companion on this
            extraordinary adventure. Choose the people who truly know Tanzania -
            choose us. Your journey awaits!
          </Text>
        </TextSection>
      </ScreenFive>
    </MainContainer>
  );
};

export default Home;
