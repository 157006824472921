import React from "react";
import Modal from "@mui/material/Modal";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import styled from "styled-components";
import { Text } from "./styledComponents/commonComponents";
import CloseIcon from "@mui/icons-material/Close";
const ModalLayout = ({ open, handleClose, content }) => {
  return (
    <>
      {open && (
        <Overlay onClick={handleClose}>
          <Modal
            style={{ overflowY: "scroll" }}
            open={open}
            onClose={handleClose}
          >
            <>
              <ModalContainer>
                <ModalHeader>
                  <CloseButton onClick={handleClose}>
                    <CloseIcon />
                  </CloseButton>
                </ModalHeader>
                <hr />
                <ModalBody>
                  {content &&
                    content.map((item) => (
                      <Timeline position="alternate">
                        <TimelineItem>
                          <TimelineOppositeContent color="text.primary">
                            <Text color="#00000">{item.Day}</Text>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            {" "}
                            <Text>{item.content}</Text>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    ))}
                </ModalBody>
              </ModalContainer>
            </>
          </Modal>
        </Overlay>
      )}
    </>
  );
};

export default ModalLayout;

const ModalContainer = styled.div`
  position: absolute;
  background-color: rgba(100, 110, 86, 0.95);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 90vw;
  max-height: 90vh;

  border-radius: 14px;

  @media (max-width: 54.8571rem) {
    width: 90vw;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
const ModalBody = styled.div`
  max-height: 70vh;
  padding: 0 20px;
  overflow-y: auto;
  padding-right: 90px;

  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }
  @media (max-width: 54.8571rem) {
    padding: 0;
  }
`;
