import styled from "styled-components";
import { motion } from "framer-motion";

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #646e56;
  position: relative;
  height: 49.2857rem;

  @media screen and (max-width: 54.8571rem) {
    flex-direction: column;
    height: 100%;
  }
`;
export const ContactButton = styled.button`
  padding: 0.5714rem 1rem 0.5714rem 1rem;
  border-radius: 1.7143rem;
  border: none;
  font-family: "Baloo Bhai 2";
  font-weight: 600;
  font-size: 1rem;
  background-color: #c4c0b4;
  width: 30%;
  box-shadow: 0 0.5em 0.5em -0.4em #3f3c32;
  cursor: pointer;
  &:hover,
  :focus {
    transform: scale(1.01);
  }
  @media (max-width: 54.8571rem) {
    width: auto;
  }
`;

export const ScreenTwo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.4286rem;

  @media screen and (max-width: 54.8571rem) {
    flex-direction: column;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  padding: ${(props) => (props.centered ? "0 2.4286rem" : "0")};
  margin: 0;
  justify-content: center;

  @media screen and (max-width: 54.8571rem) {
    text-align: center;
  }
`;

ScreenTwo.ParkContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 54.8571rem) {
    align-items: center;
    gap: 0rem;
    margin-top: 1rem;
  }
`;

ScreenTwo.ParkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  @media (max-width: 54.8571rem) {
    flex-direction: column;
    align-items: center;
  }
`;

ScreenTwo.ParkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 0;
`;
ScreenTwo.ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4286rem;
  @media (max-width: 54.8571rem) {
    padding: 0;
    margin-top: 1rem;
  }
`;

export const ScreenThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 54.8571rem) {
    padding: 0;
    margin-top: 1rem;
  }
`;

ScreenThree.TribeContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 2.4286rem;
  gap: 1.4286rem;
  margin-top: 1rem;
  @media screen and (max-width: 54.8571rem) {
    flex-direction: column;
    margin-top: 1rem;
    padding: 0;
    gap: 0;
  }
`;

ScreenThree.TribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  background-color: #c4c0b4;
`;
ScreenThree.TribeTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7143rem;
  color: black;
  padding: 2.4286rem;
  @media screen and (max-width: 54.8571rem) {
    text-align: center;
    gap: 0.3571rem;
  }
`;

export const ScreenFour = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.5714rem;
  padding: 0 2.4286rem;
  gap: 1.7143rem;
  height: 49.2857rem;

  @media screen and (max-width: 54.8571rem) {
    flex-direction: column;
    margin-top: 2rem;
    height: 100%;
    padding: 0;
  }
`;

export const ScreenFive = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding-bottom: 2.5rem;

  @media screen and (max-width: 54.8571rem) {
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  justify-content: center;
  align-items: ${(props) => (props.centered ? "center" : "left")};
  height: 100%;
  text-align: ${(props) => (props.centered ? "center" : "left")};

  padding-left: ${(props) => (props.centered ? "2.4286rem" : "4.5rem")};
  padding-right: ${(props) => (props.centered ? "2.4286rem" : "4.5rem")};

  @media (max-width: 54.8571rem) {
    padding: 0;
    text-align: center;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;
ImageWrapper.Image = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
