import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import Navbar from "./components/navbar";
import Home from "./components/Home";
import About from "./components/About";
import Safaris from "./components/Safaris";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <div>
          <Switch>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/safaris" element={<Safaris />} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
