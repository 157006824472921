import styled from "styled-components";

const titleStyles = {
  fontFamily: "Baloo Bhai 2",
  fontWeight: 700,
  fontSize: "3.4286rem",
  lineHeight: "4.8rem",
};

const semiTitleStyles = {
  fontFamily: "Baloo Bhai 2",
  fontWeight: 600,
  fontSize: "1.1429rem",
};

const normalTextStyles = {
  fontFamily: "Baloo Bhai 2",
  fontWeight: 400,
  fontSize: "1.2857rem",
};

const SemiBoldTextStyles = {
  fontFamily: "Baloo Bhai 2",
  fontWeight: 700,
  fontSize: "1.4286rem",
};
export const NormalText = styled.div`
  ${normalTextStyles}
  color: ${(props) => (props.color ? props.color : "#E4D8C8")};

  @media (max-width: 54.8571rem) {
    text-align: center;
    font-size: 1.0714rem;
  }
`;

export const SemiTitle = styled.div`
  ${semiTitleStyles}

  @media (max-width: 54.8571rem) {
    font-size: 0.9286rem;
    text-align: center;
  }
`;
export const SemiBold = styled.div`
  ${SemiBoldTextStyles}
`;

export const Title = styled.div`
  ${titleStyles}
  font-size: ${(props) => (props.fontSize ? props.fontSize : "3.4286rem")};

  @media (max-width: 54.8571rem) {
    font-size: 2.4286rem;
    line-height: 3.3571rem;
  }
`;
export const Text = NormalText;
Text.SemiTitle = SemiTitle;
Text.Title = Title;
Text.SemiBold = SemiBold;

export const MainContainer = styled.div`
  background-color: #646e56;
  max-width: 100%;
`;
