import React from "react";
import styled from "styled-components";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Text } from "./styledComponents/commonComponents";

const Footer = () => {
  return (
    <MainContainer>
      <Text.Title fontSize="2.5rem">Contact Us</Text.Title>
      <ContactContainer>
        <ContactInfo>
          <MailOutlineIcon fontSize="large" />
          <InfoHeader>Email</InfoHeader>
          <Text color="#96462f">adventure_safaris@yahoo.com</Text>
          <Text color="#96462f">adventuretours@tz2000.com</Text>
        </ContactInfo>
        <ContactInfo>
          <LocalPhoneIcon fontSize="large" />
          <InfoHeader>Phone</InfoHeader>
          <Text color="#96462f">813-789-3659</Text>
          <Text color="#96462f">
            255-27-2501357 | 255-754311100 | 255-754-290452
          </Text>
        </ContactInfo>
        <ContactInfo>
          <LocationOnOutlinedIcon fontSize="large" />
          <InfoHeader>USA</InfoHeader>
          <Text color="#96462f">
            Dallas, Texas 1808 N Irving Heights Dr Irving, TX 75061
          </Text>
        </ContactInfo>
        <ContactInfo>
          <LocationOnOutlinedIcon fontSize="large" />
          <InfoHeader>Tanzania (Head Office)</InfoHeader>
          <Text color="#96462f">Arusha, Tanzania Goliondoi Road</Text>
        </ContactInfo>
      </ContactContainer>
      <Text>
        {" "}
        <br />
        Embark on a Tanzanian adventure with Adventure Tours and Safaris – the
        people who know Tanzania better!
      </Text>
    </MainContainer>
  );
};

export default Footer;

const MainContainer = styled.div`
  background-color: #96462f;
  padding: 1.4286rem 0;
  text-align: center;
  @media (max-width: 54.8571rem) {
    padding: 0.7143rem 0;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.4286rem;
  margin-top: 1.4286rem;
  @media (max-width: 54.8571rem) {
    flex-direction: column;
    align-items: center;
    gap: 0.7143rem;
    padding: 0 1rem;
  }
`;

const ContactInfo = styled.div`
  text-align: center;
  flex: 1;
  max-width: 21.4286rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5714rem;
  box-shadow: 0 0.1429rem 0.2857rem rgba(0, 0, 0, 0.2);

  .MuiSvgIcon-root {
    font-size: 3rem;
    color: #96462f;
  }
  @media (max-width: 54.8571rem) {
    max-width: 100%;
    width: 100%;
  }
`;

const InfoHeader = styled.div`
  font-size: 1.2857rem;
  margin-top: 0.7143rem;
  font-weight: bold;
  color: #96462f;
`;
